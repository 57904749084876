/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import qs from 'query-string'

import { EventLogsResponse } from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import { QueryString, throwValidate } from '@/utilities/codec'

// ---------------------------------------------------------------------------

export type EventLogApi = {
  getEventLogs: (query: QueryString | '') => Promise<EventLogsResponse>
}

export function eventLogApiFactory(http: HttpClient): EventLogApi {
  const baseUrl = '/event-log/api/v1'

  return {
    getEventLogs(query) {
      const parsedQuery = qs.parse(query)
      const reconstructedQuery = qs.stringify({
        eventCategory: 'source',
        ...parsedQuery,
      })

      const requestUrl = `${baseUrl}/eventLogs?${reconstructedQuery}`
      return http.get(requestUrl).then(throwValidate(EventLogsResponse, 'EventLogsResponse'))
    },
  }
}
