/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as CodecUtils from '@/utilities/codec'

import { Affiliation } from '../affiliation.codecs'
import { Authorization } from '../authorization.codecs'
import { CPE } from '../cpe.codecs'
import { DetectionCriteria } from '../detection-criteria.codecs'
import { LensId, LensView, Perspective, PerspectiveName } from '../perspective.codecs'
import { withPriority } from '../priority.codecs'
import { withTemptation } from '../temptation.codecs'
import { TextSource } from '../text-source.codecs'

// ---------------------------------------------------------------------------

export type Target = t.TypeOf<typeof Target>
export const Target = withPriority(
  withTemptation(
    t.type({
      affiliation_state: Affiliation,
      authorization_state: Authorization,
      banner_data_hash: t.union([t.string, t.null]),
      banner_uuid: t.union([t.string, t.null]),
      cert_data_hash: t.union([t.string, t.null]),
      cert_uuid: t.union([t.string, t.null]),
      characteristic_tags: t.array(t.string),
      characteristics_count: t.number,
      cpe: CodecUtils.nullable(CPE),
      description: t.union([t.string, t.null]),
      description_source: TextSource,
      detection_criteria: DetectionCriteria,
      first_seen: t.union([t.string, t.null]),
      content_data_hash: t.union([t.string, t.null]),
      content_uuid: t.union([t.string, t.null]),
      headers_data_hash: t.union([t.string, t.null]),
      headers_uuid: t.union([t.string, t.null]),
      hostname: t.union([t.string, t.null]),
      hostname_id: t.union([t.string, t.undefined, t.null]),
      id: t.string,
      ip_id: t.union([t.string, t.null]),
      ip_str: t.union([t.string, t.null]),
      last_seen: t.union([t.string, t.null]),
      lens_id: LensId,
      lens_view: LensView,
      name: t.union([t.string, t.null]),
      org_id: t.union([t.string, t.null]),
      path: t.union([t.string, t.null]),
      perspective: Perspective,
      perspective_name: PerspectiveName,
      port: t.union([t.number, t.null]),
      protocol: t.union([t.string, t.null]),
      randori_notes: t.union([t.string, t.null]),
      reference: t.union([t.string, t.null]),
      screenshot_data_hash: t.union([t.string, t.null]),
      screenshot_uuid: t.union([t.string, t.null]),
      service_id: t.union([t.string, t.null]),
      thumbnail_data_hash: t.union([t.string, t.null]),
      thumbnail_uuid: t.union([t.string, t.null]),
      user_tags: t.array(t.string),
      validated_vulnerabilities_detection: t.array(t.string),
      validated_vulnerabilities_detection_count: t.number,
      validated_vulnerabilities_target: t.array(t.string),
      validated_vulnerabilities_target_count: t.number,
      vendor: t.union([t.string, t.null]),
      version: t.union([t.string, t.null]),
    }),
  ),
)
