/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { DateString, IP, nullable, nullableP, Sha1, TripleUUID, UUID } from '@/utilities/codec'

import { Affiliation } from '../affiliation.codecs'
import { Authorization } from '../authorization.codecs'
import { DetectionCriteria } from '../detection-criteria.codecs'
import { WithPerspective } from '../perspective.codecs'
import { Status } from '../status.codecs'

// ---------------------------------------------------------------------------

export const _Detection = t.type({
  affiliation_state: Affiliation,
  artifact__banner_sha: nullableP(Sha1),
  artifact__certificate_sha: nullableP(Sha1),
  artifact__content_sha: nullableP(Sha1),
  artifact__header_sha: nullableP(Sha1),
  artifact__screenshot_sha: nullableP(Sha1),
  artifact__thumbnail_sha: nullableP(Sha1),
  authority: t.boolean,
  authority_distance: t.number,
  authority_override: t.boolean,
  authorization_state: nullable(Authorization),
  authorizing_policies: t.array(t.union([UUID, t.literal('MANUALLY-AUTHORIZED')])),
  confidence: t.number,
  consolidated_target__ids: t.array(UUID),
  consolidated_target__names: t.array(t.string),
  detection_criteria: DetectionCriteria,
  detection_criteria__hostname: nullableP(t.string),
  detection_criteria__ip: nullableP(IP),
  detection_criteria__ip_str: nullableP(t.string),
  detection_criteria__path: nullableP(t.string),
  detection_criteria__port: nullableP(t.number),
  detection_criteria__port_str: nullableP(t.string),
  detection_criteria__protocols: t.array(t.string),
  discovered_service__ids: t.array(TripleUUID),
  discovered_service__names: t.array(t.string),
  discovery_path: nullable(t.array(UUID)),
  entity_type: t.literal('bdo_detection'),
  first_seen: DateString,
  id: UUID,
  id__hostname: nullableP(UUID),
  id__ip: nullableP(UUID),
  id__network: nullableP(UUID),
  id__poc: nullableP(UUID),
  id__port: nullableP(UUID),
  last_seen: DateString,
  org_id: UUID,
  status: Status,
  user_comments: t.array(t.string),
  user_comments_uuids: t.array(t.string),
  user_tags: t.array(t.string),
  validated_vulnerabilities: t.array(t.string),
  validated_vulnerabilities_count: t.number,
})

export type DetectionV2 = t.TypeOf<typeof DetectionV2>
export const DetectionV2 = t.intersection([_Detection, t.partial(WithPerspective.props)])
