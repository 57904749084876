/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { DateString, nullableP, UUID } from '@/utilities/codec'

// ---------------------------------------------------------------------------

export const EventCategory = t.keyof({
  general: null,
  source: null,
})
export type EventCategory = t.TypeOf<typeof EventCategory>

// Note: the event logs we get back from the event-log service use camelCase keys, but we convert those
// keys to snake case in the sagas to better serve our columns and cell rendering
export const EventLogFromEventLogService = t.type({
  id: UUID,
  category: EventCategory,
  type: t.string, // will need util fn to convert to UI-displayable event name
  createTime: DateString,
  expireTime: nullableP(DateString),
  author: t.string,
  body: t.union([t.record(t.string, t.string), t.record(t.string, t.undefined), t.null]), // Revisit- TBD on what body actually contains
})

export type _EventLogFromEventLogService = t.TypeOf<typeof EventLogFromEventLogService>
export type EventLogFromEventLogService = _EventLogFromEventLogService

export const EventLog = t.type({
  id: UUID,
  category: EventCategory,
  event_type: t.string,
  creation_time: DateString,
  expiration_time: nullableP(DateString),
  author: t.string,
  body: t.union([t.record(t.string, t.string), t.record(t.string, t.undefined), t.null]), // Revisit- TBD on what body actually contains
})

export type _EventLog = t.TypeOf<typeof EventLog>
export type EventLog = _EventLog

export const EventLogNormalized = t.type({
  id: UUID,
  category: EventCategory,
  event_type: t.string,
  creation_time: DateString,
  expiration_time: nullableP(DateString),
  author: t.string,
})
export type EventLogNormalized = t.TypeOf<typeof EventLogNormalized>
