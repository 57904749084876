/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import type { Artifact } from '@/codecs'
import * as CodecUtils from '@/utilities/codec'
import { isNotNil } from '@/utilities/is-not'

// ---------------------------------------------------------------------------

export const ARTIFACT_FLAGS: Record<string, number>[] = [
  { ARTIFACT_FLAG_RAW: 0 },
  { ARTIFACT_FLAG_JSON: 1 },
  { ARTIFACT_FLAG_STRING: 2 },
  { ARTIFACT_FLAG_YAML: 3 },
]

export const ARTIFACT_FLAGS_MAP: Record<string, number> = {
  ARTIFACT_FLAG_RAW: 0,
  ARTIFACT_FLAG_JSON: 1,
  ARTIFACT_FLAG_STRING: 2,
  ARTIFACT_FLAG_YAML: 3,
}

export const ARTIFACT_TYPES: Record<string, number>[] = [
  { ARTIFACT_PORTSCANNER: 4 },
  { ARTIFACT_SSL_CERT: 5 },
  { ARTIFACT_SCREENSHOT_MITM: 7 },
  { ARTIFACT_ATTACK_HOST_PROFILE: 8 },
  { ARTIFACT_SCREENSHOT_THUMBNAIL: 9 },
  { ARTIFACT_SCRAPER_META: 10 },
  { ARTIFACT_HTTP_RESPONSE_HEADERS: 11 },
  { ARTIFACT_HTTP_REQUEST_HEADERS: 12 },
  { ARTIFACT_HTTP_REDIRECTS: 13 },
  { ARTIFACT_HTTP_CONTENT: 14 },
  { ARTIFACT_SCREENSHOT: 15 },
  { ARTIFACT_SSL_CERT_META: 17 },
]

export const ARTIFACT_TYPES_MAP: Record<string, number> = {
  ARTIFACT_ATTACK_HOST_PROFILE: 8,
  ARTIFACT_HTTP_CONTENT: 14,
  ARTIFACT_HTTP_REDIRECTS: 13,
  ARTIFACT_HTTP_REQUEST_HEADERS: 12,
  ARTIFACT_HTTP_RESPONSE_HEADERS: 11,
  ARTIFACT_PORTSCANNER: 4,
  ARTIFACT_SCRAPER_META: 10,
  ARTIFACT_SCREENSHOT: 15,
  ARTIFACT_SCREENSHOT_MITM: 7,
  ARTIFACT_SCREENSHOT_THUMBNAIL: 9,
  ARTIFACT_SSL_CERT: 5,
  ARTIFACT_SSL_CERT_META: 17,
}

export const ARTIFACT_TYPES_TO_TARGET_KEY: Record<string, string> = {
  ARTIFACT_SSL_CERT: 'cert_data_hash',
  ARTIFACT_PORTSCANNER: 'banners_data_hash',
  ARTIFACT_HTTP_REQUEST_HEADERS: 'headers_data_hash',
  ARTIFACT_SCREENSHOT: 'screenshot_data_hash',
}

export const getArtifactTypeByTargetKey = (_type: unknown): string => {
  const type = CodecUtils.throwValidate(t.string, 'string')(_type)

  switch (type) {
    case 'ARTIFACT_SSL_CERT':
      return 'SSL Certificate'

    case 'ARTIFACT_PORTSCANNER':
      return 'Banner'

    case 'ARTIFACT_HTTP_CONTENT':
      return 'Http Content'

    case 'ARTIFACT_HTTP_REQUEST_HEADERS':
      return 'Header'

    case 'ARTIFACT_SCREENSHOT':
      return 'Screenshot'

    default:
      return type
  }
}

export const getArtifactTypeByNumber = (type: Artifact['artifact_type']): string | undefined => {
  if (!isNotNil(type)) {
    return // bail
  }

  return Object.keys(ARTIFACT_TYPES_MAP).find((key) => ARTIFACT_TYPES_MAP[key] === type)
}

export const getArtifactTypeString = (type?: string): string => {
  switch (type) {
    case 'ARTIFACT_ATTACK_HOST_PROFILE':
      return 'Attack Host Profile'

    case 'ARTIFACT_HTTP_CONTENT':
      return 'Http Content'

    case 'ARTIFACT_HTTP_REDIRECTS':
      return 'Redirects'

    case 'ARTIFACT_HTTP_REQUEST_HEADERS':
      return 'Request Headers'

    case 'ARTIFACT_HTTP_RESPONSE_HEADERS':
      return 'Response Headers'

    case 'ARTIFACT_PORTSCANNER':
      return 'Portscanner'

    case 'ARTIFACT_SCRAPER_META':
      return 'Scraper'

    case 'ARTIFACT_SCREENSHOT':
      return 'Screenshot'

    case 'ARTIFACT_SCREENSHOT_MITM':
      return 'Screenshot MITM'

    case 'ARTIFACT_SCREENSHOT_THUMBNAIL':
      return 'Thumbnail'

    case 'ARTIFACT_SSL_CERT':
      return 'SSL Cert'

    case 'ARTIFACT_SSL_CERT_META':
      return 'SSL Cert Meta'

    default:
      return 'Unknown'
  }
}
