/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { GateSelectors } from '@/store'
import * as EntityUtils from '@/utilities/r-entity'

import { DisplayName, humanize } from './field-display-name--ui_id'
import { isInternalField as getIsInternalField } from './is-internal-field'

// ---------------------------------------------------------------------------

// If an entity type is internal, prefix it with 'Randori -'
const internalCheck =
  (
    entityType: EntityUtils.EntityTypesTableSummary,
    fieldName: string,
    staticFlags: ReturnType<typeof GateSelectors.staticFlags>,
  ) =>
  (displayName: string) => {
    const isInternalField = getIsInternalField(entityType as EntityUtils.EntityType, false, staticFlags)

    return `${isInternalField(fieldName) ? 'Randori - ' : ''}${displayName}`
  }

export const getFieldDisplayNameByEntityAndFieldName = (
  entityType: EntityUtils.EntityTypesTableSummary,
  fieldName: string,
  staticFlags: ReturnType<typeof GateSelectors.staticFlags>,
) => {
  const defaultDisplayName = humanize(fieldName)
  const fieldInternalCheck = internalCheck(entityType, fieldName, staticFlags)
  const maybePrefixedDisplayName = fieldInternalCheck(defaultDisplayName)

  // We need to override the "RANDORI" prefix for the screenshot column
  if (fieldName === 'artifact__thumbnail_sha' && entityType === 'bdo_detection') {
    return GENERIC_FIELD_NAME_MAP.get(fieldName)
  }

  if (ENTITY_TYPE_SPECIFIC_FIELD_NAME_MAP.has(`${fieldName}--${entityType}`)) {
    return fieldInternalCheck(
      ENTITY_TYPE_SPECIFIC_FIELD_NAME_MAP.get(`${fieldName}--${entityType}`) ?? maybePrefixedDisplayName,
    )
  }

  if (GENERIC_FIELD_NAME_MAP.has(fieldName)) {
    const fieldDisplayName = GENERIC_FIELD_NAME_MAP.get(fieldName)

    // const fieldDisplayName =
    //   fieldName === 'target_num_detections' && entityType === 'detection_target'
    //     ? 'Related Detections'
    //     : GENERIC_FIELD_NAME_MAP.get(fieldName)

    return fieldInternalCheck(fieldDisplayName ?? maybePrefixedDisplayName)
  }

  return defaultDisplayName
}

// ---------------------------------------------------------------------------

export type FieldDisplayName =
  | "Attacker's Objective"
  | ''
  | 'Activity Description'
  | 'Activity Name'
  | 'Activity Type'
  | 'Activity and Matching Entity'
  | 'Applicability - Temptation'
  | 'Applicable Entities'
  | 'Applicable Targets'
  | 'Artifacts'
  | 'Asset'
  | 'Authority Dist Max'
  | 'Authority Dist Min'
  | 'Authority'
  | 'Authorization Rule'
  | 'Authorization Source'
  | 'Authorization'
  | 'Authorized Detections'
  | 'Authorized'
  | 'Banner'
  | 'CVEs'
  | 'Certificate'
  | 'Consolidated Targets'
  | 'Consolidated Target IDs'
  | 'Content'
  | 'Created By'
  | 'Criticality - Temptation'
  | 'Deleted Discovery Relation Count'
  | 'Description'
  | 'Destination Email'
  | 'Destination Entity Type'
  | 'Destination Host'
  | 'Destination IP'
  | 'Destination Mac'
  | 'Destination Misc'
  | 'Destination Network'
  | 'Destination Path'
  | 'Destination Port'
  | 'Destination'
  | 'Detected Services'
  | 'Detected Service IDs'
  | DisplayName
  | 'Duration'
  | 'Edited By'
  | 'Edited On'
  | 'Enable'
  | 'End Date / Time'
  | 'Enumerability - Temptation'
  | 'Exploitablility - Temptation'
  | 'Header'
  | 'Hostname'
  | 'IP'
  | 'Impact'
  | 'Implant ID'
  | 'Implication'
  | 'Interface IPs'
  | 'Interval'
  | 'Last Queued'
  | 'MITRE Mitigations'
  | 'MITRE Tactics'
  | 'MITRE Techniques'
  | 'Matching Activities'
  | 'Matching Entity IP'
  | 'Matching Entity Type'
  | 'Matching Entity'
  | 'Nickname'
  | 'Objective Result'
  | 'Origination Email'
  | 'Origination Host'
  | 'Origination IP'
  | 'Origination Mac'
  | 'Origination Misc'
  | 'Origination'
  | 'OS'
  | 'Path'
  | 'Perspective'
  | 'Perspective Name'
  | 'Policy Name'
  | 'Port'
  | 'Post Exploit Potential - Temptation'
  | 'Private Weakness - Temptation'
  | 'Protocols'
  | 'Public Weakness - Temptation'
  | 'Redirector IP'
  | 'Requires Authorization'
  | 'Research Potential - Temptation'
  | 'Run State'
  | 'Screenshot'
  | 'Source'
  | 'Source Type'
  | 'Start Date / Time'
  | 'Status'
  | 'Targets'
  | 'Thumbnail'
  | 'Traffic Source'
  | 'Type'
  | 'Updated Entities'
  | 'Vendor, Service, Version'
  | 'Vendor, Service, Version, Target'

const makeEntityTypeSpecificFieldNameMap = () => {
  type _CellTuple = [string, FieldDisplayName]

  const columns: _CellTuple[] = [
    ['activities_count--exception_policy', 'Matching Activities'],
    ['activity_type_name--activity_configuration', 'Activity Type'],
    ['attackers_perspective--activity_configuration', "Attacker's Objective"],
    ['author--event_log', 'Edited By'],
    ['authority_display--activity_configuration', 'Authority'],
    ['authority_distance_max--activity_configuration', 'Authority Dist Max'],
    ['authority_distance_min--activity_configuration', 'Authority Dist Min'],
    ['configuration__description--activity_instance', 'Activity Description'],
    ['configuration__name--activity_instance', 'Activity and Matching Entity'],
    ['count__artifacts--activity_instance', 'Artifacts'],
    ['count__entities_updated--activity_instance', 'Updated Entities'],
    ['count__relationships_deleted--activity_instance', 'Deleted Discovery Relation Count'],
    ['created_on--redirector', 'Activated'],
    ['cves--activity_configuration', 'CVEs'],
    ['default_perspective_id--source', 'Perspective'],
    ['detection_authorization', 'Authorization'],
    ['display_name--asset', 'Asset'],
    ['dst_email--runbook', 'Destination Email'],
    ['dst_host--runbook', 'Destination Host'],
    ['dst_ip--runbook', 'Destination IP'],
    ['dst_mac--runbook', 'Destination Mac'],
    ['dst_misc--runbook', 'Destination Misc'],
    ['dst_network--runbook', 'Destination Network'],
    ['dst_path--runbook', 'Destination Path'],
    ['dst_port--runbook', 'Destination Port'],
    ['entities_count--activity_configuration', 'Applicable Entities'],
    ['implant_id--source', 'Implant ID'],
    ['implant_type--asset', 'Source Type'],
    ['implant_type--source', 'Type'],
    ['external_ip--redirector', 'Redirector IP'],
    ['filter_data--authorization_policy', 'Authorization Rule'],
    ['instance__state--activity_instance', 'Run State'],
    ['instance__time_duration--activity_instance', 'Duration'],
    ['instance__time_end--activity_instance', 'End Date / Time'],
    ['instance__time_start--activity_instance', 'Start Date / Time'],
    ['interfaces--asset', 'Interface IPs'],
    ['is_active--authorization_policy', 'Enabled'],
    ['is_dirty--authorization_policy', 'Status'],
    ['last_planned_at--activity_configuration', 'Last Queued'],
    ['matching_entity__name--activity_instance', 'Matching Entity'],
    ['matching_entity__type--activity_instance', 'Matching Entity Type'],
    ['message--event_log', 'Description'],
    ['mitre__mitigations--activity_instance', 'MITRE Mitigations'],
    ['mitre__tactics--activity_instance', 'MITRE Tactics'],
    ['mitre__techniques--activity_instance', 'MITRE Techniques'],
    ['mitre_mitigations--activity_configuration', 'MITRE Mitigations'],
    ['mitre_tactics--activity_configuration', 'MITRE Tactics'],
    ['mitre_techniques--activity_configuration', 'MITRE Techniques'],
    ['name--activity_configuration', 'Activity Name'],
    ['name--authorization_policy', 'Policy Name'],
    ['name--exception_policy', 'Policy Name'],
    ['name--policy', 'Policy Name'],
    ['name--runbook', 'Runbook Title'],
    ['name--service', 'Service Name'],
    ['objective__status--activity_instance', 'Objective Result'],
    ['period--activity_configuration', 'Interval'],
    ['perspective_id--asset', 'Perspective'],
    ['required_authorization--activity_configuration', 'Requires Authorization'],
    ['sources--asset', 'Source'],
    ['src_email--runbook', 'Origination Email'],
    ['src_host--runbook', 'Origination Host'],
    ['src_ip--runbook', 'Origination IP'],
    ['src_mac--runbook', 'Origination Mac'],
    ['src_misc--runbook', 'Origination Misc'],
    ['target_num_detections--detection_target', 'Related Detections'],
    ['targets_count--activity_configuration', 'Applicable Targets'],
    ['traffic_source__ip_name--activity_instance', 'Traffic Source'],
    ['uid--runbook', 'Runbook UID'],
    ['validated_vulnerabilities_detection--detection_target', 'Validated Vulnerabilities'],
    ['validated_vulnerabilities_detection--target', 'Validated Vulnerabilities'],
    ['validated_vulnerabilities_detection_count--detection_target', 'Validated Vulnerabilities Count'],
    ['validated_vulnerabilities_detection_count--target', 'Validated Vulnerabilities Count'],
    ['validated_vulnerabilities_target--detection_target', 'Validated Vulnerabilities'],
    ['validated_vulnerabilities_target--target', 'Validated Vulnerabilities'],
    ['validated_vulnerabilities_target_count--detection_target', 'Validated Vulnerabilities Count'],
    ['validated_vulnerabilities_target_count--target', 'Validated Vulnerabilities Count'],
    ['vendor-service-version--detection_target', 'Vendor, Service, Version, Target'],
    ['vendor-service-version--service', 'Vendor, Service, Version'],
    ['vendor-service-version--target', 'Vendor, Service, Version, Target'],
  ]

  return new Map(columns)
}

export const ENTITY_TYPE_SPECIFIC_FIELD_NAME_MAP = makeEntityTypeSpecificFieldNameMap()

// ---------------------------------------------------------------------------

const makeGenericFieldNameMap = () => {
  type _CellTuple = [string, FieldDisplayName]

  const columns: _CellTuple[] = [
    ['affected_orgs', 'Organizations'],
    ['applicability', 'Applicability - Temptation'],
    ['artifact__banner_sha', 'Banner'],
    ['artifact__certificate_sha', 'Certificate'],
    ['artifact__content_sha', 'Content'],
    ['artifact__header_sha', 'Header'],
    ['artifact__screenshot_sha', 'Screenshot'],
    ['artifact__thumbnail_sha', 'Screenshot'],
    ['artifact_data_hash', 'Data Hash'],
    ['artifact_detection_criteria', 'Data Hash'],
    ['artifact_first_seen', 'First Seen'],
    ['artifact_last_seen', 'Last Seen'],
    ['attack_authorization', 'Authorization'],
    ['authorization_state', 'Authorization'],
    ['authorizing_policies', 'Authorization Source'],
    ['bar_state', 'BAR State'],
    ['blueprint_approval_status', 'Process Blueprint Status'],
    ['characteristic_tags', 'Characteristics'],
    ['consolidated_target__ids', 'Consolidated Target IDs'],
    ['consolidated_target__names', 'Consolidated Targets'],
    ['cpe_str', 'CPE Name'],
    ['create_time', 'Creation Date'],
    ['created_at', 'Created On'],
    ['creator_user_name', 'Created By'],
    ['criticality', 'Criticality - Temptation'],
    ['cve', 'CVE Name'],
    ['cvss', 'CVSS Score'],
    ['description', 'Service Description'],
    ['detection_authorization_state', 'Authorization'],
    ['detection_criteria', 'Location'],
    ['detection_criteria__hostname', 'Hostname'],
    ['detection_criteria__ip', 'INET'],
    ['detection_criteria__ip_str', 'IP'],
    ['detection_criteria__path', 'Path'],
    ['detection_criteria__port', 'Port'],
    ['detection_criteria__protocols', 'Protocols'],
    ['discovered_service__ids', 'Detected Service IDs'],
    ['discovered_service__names', 'Detected Services'],
    ['dst_email_many', 'Destination Email(s)'],
    ['dst_host_many', 'Destination Host(s)'],
    ['dst_ip_many', 'Destination IP(s)'],
    ['dst_mac_many', 'Destination MAC(s)'],
    ['dst_misc_many', 'Destination Misc(s)'],
    ['dst_network_many', 'Destination Network(s)'],
    ['dst_path_many', 'Destination Path(s)'],
    ['dst_port_many', 'Destination Port(s)'],
    ['edited_at', 'Edited On'],
    ['editor_user_name', 'Edited By'],
    ['end_time', 'End Date / Time'],
    ['entity_type', 'Entity Type'],
    ['enumerability', 'Enumerability - Temptation'],
    ['exploitability', 'Exploitablility - Temptation'],
    ['host_ips', 'Host IP(s)'],
    ['hostnames', 'Hostname'],
    ['id', 'ID'],
    ['impact_score', 'Impact'],
    ['implant_nick', 'Implant Nickname'],
    ['instance_count', 'Related Targets'],
    ['ip_count', 'IP Count'],
    ['ip_range', 'IP Range'],
    ['ip_str', 'IP Address'],
    ['ips', 'IP Address'],
    ['is_favorite', 'Favorite'],
    ['is_global', 'Global View'],
    ['matching_entity__ip', 'Matching Entity IP'],
    ['message--event_log', 'Description'],
    ['name', 'Name'],
    ['network_str', 'Network'],
    ['nick', 'Nickname'],
    ['objective__attacker_perspective', "Attacker's Objective"],
    ['objective__description', 'Description'],
    ['objective__implication', 'Implication'],
    ['org_id', 'Org ID'],
    ['org_shortname', 'Short Name'],
    ['os_type', 'OS'],
    ['ostype', 'OS'],
    ['person_first_name', 'First Name'],
    ['person_last_name', 'Last Name'],
    ['perspective', 'Perspective Name'], // 'perspective_name' in the backend has historically been 'Perspective' in the frontend, hence the swap
    ['perspective__name', 'Perspective'],
    ['perspective_name', 'Perspective'],
    ['perspective_type', 'Perspective Type'],
    ['post_exploit', 'Post Exploit Potential - Temptation'],
    ['priority_score', 'Priority'],
    ['private_weakness', 'Private Weakness - Temptation'],
    ['public_weakness', 'Public Weakness - Temptation'],
    ['research', 'Research Potential - Temptation'],
    ['service_deleted', 'Deleted'],
    ['src_email_many', 'Origination Email(s)'],
    ['src_host_many', 'Origination Host(s)'],
    ['src_ip_many', 'Origination IP(s)'],
    ['src_mac_many', 'Origination MAC(s)'],
    ['src_misc_many', 'Origination Misc(s)'],
    ['start_time', 'Start Date / Time'],
    ['suggestion_bogus', 'Bogus'],
    ['target_count', 'Related Targets'],
    ['target_first_seen', 'First Seen'],
    ['target_id', 'Target ID'],
    ['target_last_seen', 'Last Seen'],
    ['target_num_authorized_detections', 'Authorized Detections'],
    ['target_num_detections', 'Detections'],
    ['target_temptation', 'Temptation'],
    ['technique_ids', 'Technique IDs'],
    ['ui-actions', ''],
    ['ui-checkbox', ''],
    ['user_tags', 'Tags'],
    ['vendor', 'Service Vendor'],
    ['version', 'Service Version'],
  ]

  return new Map(columns)
}

export const GENERIC_FIELD_NAME_MAP = makeGenericFieldNameMap()
